import { items } from "utils/permissions";

// TypeScript Interface for Navigation Items
interface NavItem {
  name: string;
  path: string;
  icon?: {
    type: string;
    path: string;
  };
  end: boolean;
  permissions: string;
  experiment?: string | false;
  submenus?: Record<string, Submenu>;
}

interface Submenu {
  name: string;
  path: string;
  end: boolean;
  permissions: string;
}

// Utility Function for Creating Submenus
const createSubmenu = (name: string, path: string, permissions: string): Submenu => ({
  name,
  path,
  end: false,
  permissions,
});

// Navigation Items
export const myAccountNavItems: Record<string, NavItem> = {
  home: {
    name: "Dashboard",
    path: "",
    icon: { type: "material-symbol", path: "space_dashboard" },
    end: true,
    permissions: items.navigation.HOME,
    experiment: false,
  },
  listings: {
    name: "Listings",
    path: "properties",
    icon: { type: "material-symbol", path: "home_work" },
    end: false,
    permissions: items.navigation.PROPERTIES,
    experiment: false,
    submenus: {
      create: createSubmenu("Create", "/dashboard/properties/create", items.navigation.PROPERTIES),
      list: createSubmenu("List", "/dashboard/properties/list", items.navigation.PROPERTIES),
    },
  },
  enquiries: {
    name: "Enquiries",
    path: "enquiries",
    icon: { type: "material-symbol", path: "list" },
    end: false,
    permissions: items.navigation.ENQUIRIES,
    experiment: false,
    submenus: {
      create: createSubmenu("Create", "/dashboard/enquiries/create", items.navigation.ENQUIRIES),
      list: createSubmenu("List", "/dashboard/enquiries/list", items.navigation.ENQUIRIES),
      report: createSubmenu("Report", "/dashboard/enquiries/report", items.actions.ENQUIRIES_REPORT)
    },
  },
  bookings: {
    name: "Bookings",
    path: "bookings/list",
    icon: { type: "material-symbol", path: "calendar_month" },
    end: false,
    permissions: items.navigation.BOOKINGS,
    experiment: false,
  },
  clients: {
    name: "Clients",
    path: "clients",
    icon: { type: "material-symbol", path: "folder_supervised" },
    end: true,
    permissions: items.navigation.CLIENTS,
    experiment: false,
  },
  tracker: {
    name: "Tracker",
    path: "tracker",
    icon: { type: "material-symbol", path: "trending_up" },
    end: true,
    permissions: items.navigation.TRACKER,
    experiment: false,
  },
  landlords: {
    name: "Landlords",
    path: "landlords",
    icon: { type: "material-symbol", path: "group_search" },
    end: false,
    permissions: items.navigation.LANDLORDS,
    experiment: false,
    submenus: {
      create: createSubmenu("Create", "/dashboard/landlords/create", items.navigation.LANDLORDS),
      list: createSubmenu("List", "/dashboard/landlords/list", items.navigation.LANDLORDS)
    },
  },
  agents: {
    name: "Agents",
    path: "agents",
    icon: { type: "material-symbol", path: "real_estate_agent" },
    end: false,
    permissions: items.navigation.AGENTS,
    experiment: false,
    submenus: {
      create: createSubmenu("Create", "/dashboard/agents/create", items.navigation.AGENTS),
      list: createSubmenu("List", "/dashboard/agents/list", items.navigation.AGENTS),
    },
  },
  companies: {
    name: "Companies",
    path: "companies",
    icon: { type: "material-symbol", path: "corporate_fare" },
    end: false,
    permissions: items.navigation.COMPANIES,
    experiment: false,
    submenus: {
      create: createSubmenu("Create", "/dashboard/companies/create", items.navigation.COMPANIES),
      list: createSubmenu("List", "/dashboard/companies/list", items.navigation.COMPANIES),
    },
  },
  accounts: {
    name: "Accounts",
    path: "accounts",
    icon: { type: "material-symbol", path: "manage_accounts" },
    end: false,
    permissions: items.navigation.ACCOUNTS,
    experiment: false,
  },
  settings: {
    name: "Settings",
    path: "settings",
    icon: { type: "material-symbol", path: "settings" },
    end: false,
    permissions: items.navigation.ENQUIRIES,
    experiment: false,
  },
  administration: {
    name: "Admin",
    path: "administration",
    icon: { type: "material-symbol", path: "key" },
    end: false,
    permissions: items.navigation.ADMINISTRATION,
    experiment: 'Admin',
  },
  help: {
    name: "Help",
    path: "help",
    icon: { type: "material-symbol", path: "help" },
    end: false,
    permissions: items.navigation.HELP,
    experiment: false,
  },
};