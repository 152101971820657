import { type FC } from "react";
import classes from './MyAccountNavigation.module.scss';

import { useCurrentUser } from "context/CurrentUserProvider";

import { hasExperiment } from "utils";
import { hasPermission } from "utils/permissions";
import { NavItem } from "components/shared";
import { myAccountNavItems } from "constant";
import { lArray } from "utils";

const MyAccountNavigation: FC = () => {
    const { currentUser } = useCurrentUser();

    const isAuthorizedForRoute = (permissions: any, experiment?: any): boolean => {
        if (!currentUser) return false;
    
        const hasRequiredPermissions = hasPermission(currentUser.role, permissions);
        const hasRequiredExperiment = experiment ? hasExperiment(currentUser.experiments, experiment) : true;
    
        return hasRequiredPermissions && hasRequiredExperiment;
    };
    
    if (!currentUser) return;

    return (
        <div className={ classes.navigation }>
            <div className={ classes.navigation__logo }> 
                <img src="/images/myhomeisyours-house-logo.png" alt="My Home Is Yours" />
            </div>
            <div className={ classes.navigation__container }>
                
                <div className={ classes.navigation__list }>
                    { lArray(myAccountNavItems).map(({ path, icon, permissions, end, name, submenus, experiment}: any, index) => (
                        isAuthorizedForRoute(permissions, experiment) && (
                            <NavItem 
                                key={ index }
                                href={ `/dashboard/${path}` } 
                                icon={ icon } 
                                active={ true }
                                color={ name.split(" ").join("").toLowerCase() } 
                                submenus={ submenus && submenus }
                                end={ end } 
                            >
                                { name }
                            </NavItem>
                        )
                    )) }
                </div>
            </div>
        </div>
    )
}

export default MyAccountNavigation;