import { lazy, type LazyExoticComponent } from 'react';

export enum Modals {
    CreateAccountModal,
    DeleteAccountModal,
    AssignEnquiryModal,
    DeleteEnquiryModal,
    LandlordSyncModal,
    ResetEnquiryModal,
    LandlordInfoModal,
    BookingModalStatus,
    UpdateSupplyAndPriceModal,
    TemporaryPropertyModal,
    LandlordMessageTemplateModal,
    CostWarningMessageModal,
    EditTemporaryPropertyModal
}

export const modalsConfig: Record<Modals, LazyExoticComponent<any>> = {
    [Modals.CreateAccountModal]: lazy(() => import('../CreateAccountModal')),
    [Modals.DeleteAccountModal]: lazy(() => import('../DeleteAccountModal')),
    [Modals.AssignEnquiryModal]: lazy(() => import('../AssignEnquiryModal')),
    [Modals.DeleteEnquiryModal]: lazy(() => import('../DeleteEnquiryModal')),
    [Modals.LandlordSyncModal]: lazy(() => import('../LandlordSyncModal')),
    [Modals.ResetEnquiryModal]: lazy(() => import('../ResetEnquiryModal')),
    [Modals.LandlordInfoModal]: lazy(() => import('../LandlordInfoModal')),
    [Modals.BookingModalStatus]: lazy(() => import('../BookingStatusModal')),
    [Modals.UpdateSupplyAndPriceModal]: lazy(() => import('../UpdateSupplyAndPriceModal')),
    [Modals.TemporaryPropertyModal]: lazy(() => import('../TemporaryPropertyModal')),
    [Modals.LandlordMessageTemplateModal]: lazy(() => import('../LandlordMessageTemplateModal')),
    [Modals.CostWarningMessageModal]: lazy(() => import('../CostWarningMessageModal')),
    [Modals.EditTemporaryPropertyModal]: lazy(() => import('../EditTemporaryPropertyModal'))
}