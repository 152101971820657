import { lazy } from "react";

interface RouteConfig {
  index?: boolean;
  exact?: boolean;
  path: string;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
}

// Account Views
const View = lazy(() => import("views/account/View"));
const Accounts = lazy(() => import("views/account/accounts/Accounts"));
const AccountDetails = lazy(() => import("views/account/accounts/AccountDetails"));

// Property Views
const Listings = lazy(() => import("views/account/properties/list/Listings"));
const Property = lazy(() => import("views/account/properties/details/Property"));
const CreateProperty = lazy(() => import("views/account/properties/create/CreateProperty"));
const CreatedPropertyScreen = lazy(() => import("views/account/properties/create/CreatedPropertyScreen"));

// Settings
const Settings = lazy(() => import("views/account/global/Settings"));

// Enquiries
const Enquiries = lazy(() => import("views/account/enquiries/Enquiries"));
const EnquiriesReport = lazy(() => import("views/account/enquiries/Report"));
const Enquiry = lazy(() => import("views/account/enquiries/details/Enquiry"));
const CreateEnquiry = lazy(() => import("views/account/enquiries/Step1-Create"));
const ChosenPropertyHistory = lazy(() => import("views/account/enquiries/details/ChosenPropertyHistory"));

// Companies
const Companies = lazy(() => import("views/account/companies/Companies"));
const CreateCompany = lazy(() => import("views/account/companies/create/CreateCompany"));
const CompanyDetails = lazy(() => import("views/account/companies/details/Company"));

// Clients
const Clients = lazy(() => import("views/account/clients/Clients"));
const ClientDetails = lazy(() => import("views/account/clients/details/Client"));

// Agents
const CreateAgent = lazy(() => import("views/account/agents/CreateAgent"));
const Agents = lazy(() => import("views/account/agents/Agents"));

// Bookings
const Bookings = lazy(() => import("views/account/bookings/Bookings"));
const BookingDetails = lazy(() => import("views/account/bookings/details/Booking"));

// Tracker
const Tracker = lazy(() => import("views/account/tracker/Tracker"));

// Landlords
const CreateLandlord = lazy(() => import("views/account/landlord/CreateLandlord"));
const Landlords = lazy(() => import("views/account/landlord/list/Landlords"));
const LandlordDetails = lazy(() => import("views/account/landlord/details/Landlord"));

// Help
const Administration = lazy(() => import("views/admin/Administration"));

// Help
const Help = lazy(() => import("views/help/Help"));

// Route Factory Function
const createRoute = (path: string, component: React.LazyExoticComponent<any>, index = false): RouteConfig => ({
  path,
  component,
  index,
});

// Routes Configuration
const routes: Record<string, RouteConfig> = {
  home: createRoute("", View, true),
  accounts: createRoute("accounts", Accounts),
  accountDetails: createRoute("accounts/:accountId", AccountDetails),
  listings: createRoute("properties/list", Listings),
  property: createRoute("properties/list/:propertyId", Property),
  createProperty: createRoute("properties/create", CreateProperty),
  createdPropertyScreen: createRoute("properties/success", CreatedPropertyScreen),
  settings: createRoute("settings", Settings),
  clients: createRoute("clients", Clients),
  clientDetails: createRoute("clients/:clientId", ClientDetails),
  enquiries: createRoute("enquiries/list", Enquiries),
  enquiriesReport: createRoute("enquiries/report", EnquiriesReport),
  createEnquiry: createRoute("enquiries/create", CreateEnquiry),
  enquiryDetails: createRoute("enquiries/list/:enquiryId", Enquiry),
  EnquiryHistory: createRoute("enquiries/list/:enquiryId/properties/:chosenPropertyId/history", ChosenPropertyHistory),
  bookings: createRoute("bookings/list", Bookings),
  bookingDetails: createRoute("bookings/list/:bookingId", BookingDetails),
  companies: createRoute("companies/list", Companies),
  createCompany: createRoute("companies/create", CreateCompany),
  companyDetails: createRoute("companies/list/:companyId", CompanyDetails),
  agents: createRoute("agents/list", Agents),
  createAgent: createRoute("agents/create", CreateAgent),
  tracker: createRoute("tracker", Tracker),
  createLandlord: createRoute("landlords/create", CreateLandlord),
  landlords: createRoute("landlords/list", Landlords),
  landlordDetails: createRoute(`landlords/list/:landlordId`, LandlordDetails),
  administration: createRoute("administration", Administration),
  help: createRoute("help", Help)
};

export default routes;